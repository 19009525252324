.navbar-public {
    min-height: 3rem;
}

.content-underneath-navbar {
    min-height: calc(100vh - 76px - 75px);
    position: relative;
}

.navText {
    color: #000;
}
