.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 5;
    top: 0;
    left: 0;
    background-color: white;
    /*opacity: 0.9;*/
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%
}

.header-user-image{
    height: 3rem;
    width: 3rem;
    border-radius: 50%;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
}

@media (max-width: 800px) {

    .navbar-bottom-bar {
        background-color: gray; /* Green background */
        border: solid lightgrey; /* Green border */
        border-width: 1px 0 0 0;
        color: pink; /* White text */
        cursor: pointer; /* Pointer/hand icon */
        float: left; /* Float the buttons side by side */
        width: 100%;
        left: 0;
        position: fixed;
        text-align: center;
        bottom: 0;
        background-color: white;
        min-height: 70px;
        z-index: 1301; /*This is high because of the bottom drawer */

    }

    .bottom-bar-icon {
        font-size: 1.5rem;
    }

    .titleWrapper#hamburger {
        visibility: hidden;
        position: absolute;
    }

    .titleWrapper#account {
        text-align: left;

    }
}

/*When bigger then 800 pixels. Dont show the bottom nav bar.*/
@media (min-width: 801px) {

    .navbar-bottom-bar {
        visibility: hidden;
        position: absolute;
    }

}

.bottom-bar-item {
    width: 100%;
    color: gray;
    min-height: 100%;
}

.nav-item.list-group-item {
    margin: 0;
    box-shadow: 0 0 0 0;
}

.sidenav.closed {
    width: 0;
    transition: 0.15s;
}

.sidenav.open {
    width: 250px;
}

.list-group-item {
    background-color: white

}

#mySidenav {
    z-index: 6;
}

.sidenav.list-group-item {
    background-color: purple;
}

.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: black;
    display: block;
    transition: 0.2s;
}

.sidenav a:hover {
    color: #959898;
}

.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
    color: black;
}


/*CSS When screen is smaller then XXX px*/
@media (max-width: 475px) {
    .custom-navbar {
        min-height: 6rem;
    }

    .title#account {
        top: calc(50% - 1.5rem);
    }

    #hamburger {
        padding: 0 0;
    }

    .hidden-on-mobile{
        visibility: hidden;
    }

}



/*CSS When screen is wider then XXX px*/
@media (min-width: 476px) {
    .custom-navbar {
        min-height: 3rem;
    }
}

.title {
    display: inline-block;
    text-align: center;
    top: calc(50% - 0.75rem)
}

.titleWrapper {
    display: block;
    padding: 0 1.25rem;
    position: relative;
    font-weight: bolder;
}


.hamburgerMenuButton {
    display: inline-block;
    text-align: left;
    top: calc(50% - 1.5em);
}
