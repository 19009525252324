.title-landing-page {
    font-size: 2rem;
    font-weight: bold;
}

.sub-title {
    font-size: 1rem;
    font-weight: bold;
    color: var(--orange);
}

.col--nb {
    margin-bottom: 24px;
}

.giff {
    border-radius: 15px;
    max-width: 300px;
}

.col--nb .lead {
    font-weight: bold;
}

.download-app {
    max-width: 150px;
    min-width: 100px;
}

.start-page .area {
    padding: 1rem;
}

@media (min-width: 768px) {
    .start-page .area {
        padding: 2rem 3rem 2rem 3rem;
    }
}



.mobile-stepper-button {
    position: absolute;
    top: 0;
    height: calc(100% - 2.5rem);
    width: 2rem;
    background: var(--blue-dark);
    color: white;
}

.mobile-stepper-button.next {
    right: 0;
}

.mobile-stepper-button.previous {
    left: 0;
}


