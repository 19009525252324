.loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid rgba(0, 123, 255, 0.97); /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
    margin: 10px;
    justify-content: center;
    align-items: center;
    /*position: relative;*/
    /*left: 50%;*/
    /*top: 50%;*/
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.loader-text{
    /*margin: 10px;*/
}
