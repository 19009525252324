body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


@media (max-width: 480px) {

    body {
        padding: 0;
    }

    .navbar-fixed-top, .navbar-fixed-bottom, .navbar-static-top {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
    }
}

.container {
    /*background-color: pink;*/
}

@media (max-width: 1024px ) and (min-width: 480px) {
    .container {
        min-width: 100%;
    }

    body {
        padding: 0;
    }

    .navbar-fixed-top, .navbar-fixed-bottom, .navbar-static-top {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
    }
}

:root {
    --blue: #4BA9FF;
    --blue-dark: #4693e4;
    --orange: #faa22b;
    --orange-dark: #e18a2b;
}

.center {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
}