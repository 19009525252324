.hidden {
    position: absolute;
    visibility: hidden;
}

.btn {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
}

.btn-warning {
    box-shadow: 0 0;
}

body {
    height: 100%;
    width: 100%;
    position: absolute;
}

/*Here custom CSS for the whole project. */

.text-orange {
    color: var(--orange);
}


.form-control {
    /*border-width: 0 0 1px 0;*/
    border-color: #BABABA;
    border-radius: 10px;
}

.lead {
    margin-bottom: 5px;
    color: var(--orange);
    font-weight: 400;
}

/*body {*/
/*    font-family:  sans-serif*/
/*}*/

.text {
    color: var(--orange);
}

.bg-white {
    background-color: var(--white);
}


.btn-primary {
    background-color: var(--blue);
    border-color: var(--blue);
}

.btn-primary:hover {
    background-color: var(--blue-dark);
    border-color: var(--blue-dark);
}

.btn {
    border-radius: 20px;
    font-family: Century Gothic, Helvetica, Arial, sans-serif;
    font-weight: bold;
}

.global {
    min-height: 100vh;
    padding-bottom: 80px;
    width: 100%;
}

body {
    background-color: rgba(240, 242, 245, 0.8);
}

.btn-margin-top {
    margin-top: 1rem;
}

.btn-margin-bottom {
    margin-bottom: 1rem;
}

.btn-margin {
    margin: 1rem;
}

.btn-margin-right {
    margin-right: 1rem;
}

.btn-margin-left {
    margin-left: 1rem;
}

.btn-success{
    background-color: var(--orange);
    border-color: var(--orange);
}

.btn-success:hover{
    background-color: var(--orange-dark);
    border-color: var(--orange-dark);
}

